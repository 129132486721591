import { ChangeDetectorRef, Component, ElementRef, HostListener, Renderer2, ViewChild  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
 
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent {
  isSticky: boolean = false;
  logo: string = 'assets/images/logo/logo-11.png';
  isCollapsed = true;
  innerWidth: any;

  constructor(public translate: TranslateService){
    if(localStorage.getItem('lang')) this.setLang(localStorage.getItem('lang'));
			else this.setLang('fr');  console.log('currentLang:', this.translate.currentLang);

      this.onResize();
  } 

  setLang(lang: any){
		this.translate.use(lang);
		localStorage.setItem('lang', lang)
	}
 
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth; console.log('this.innerWidth:', this.innerWidth);
  }

  scrollToHome() {
    const section = document.getElementById('about');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
  scrollToSection(sectionId: string) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    let element: HTMLElement = document.getElementById('closeNav') as HTMLElement;
    element.click();
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollTop > 100) {  
      this.isSticky = true;
      this.logo = 'assets/images/logo/logo-11.png';
    } else {
      this.isSticky = false;
      this.logo = 'assets/images/logo/logo-11.png';
    }
  }
 
}
